import { Header } from "./Header";
import { PropsWithChildren } from "react";
import { FlashMessage } from "../FlashMessage";

export const Layout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div>
      <Header />
      <FlashMessage />
      <main>{children}</main>
    </div>
  );
};
