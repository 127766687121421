import { useAuthentication } from "../../components/AuthenticationProvider";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Layout } from "../../components/Layout";

export const LoginPage = () => {
  const { isLoaded, isSignedIn } = useAuthentication();
  const router = useRouter();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      router.replace("/sections");
    }
    // useAuthenticationの状態の変更によってのみリダイレクトしたいのでrouterはdepsから除外している
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, isSignedIn]);

  const homeURL =
    typeof window !== "undefined"
      ? new URL("/", window.location.toString()).toString()
      : "";
  const loginURL = new URL("/sign_in", process.env.BORON_URL);
  loginURL.search = new URLSearchParams({ url: homeURL }).toString();

  return (
    <Layout>
      <section className="hero">
        <div className="hero-body">
          {!isLoaded ? (
            <div className="container has-text-centered loading-auth">
              読み込み中...
            </div>
          ) : (
            <div className="container has-text-centered">
              <h2>Studyplus for School ログイン</h2>
              <div className="column is-4 is-offset-4">
                <div className="box">
                  <div className="field">
                    <div className="control">
                      <a
                        href={loginURL.toString()}
                        className="button is-block is-info is-fullwidth"
                      >
                        ログイン
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};
